import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form, Input, SubmitButton, Switch } from "formik-antd";
import { Card, Col, Row, Space, Spin } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import Text from "antd/es/typography/Text";
import ReactQuill from "react-quill-2";
import "react-quill/dist/quill.snow.css";
import { LocalizedText } from "../../../../../types/LocalizedText";
import FlagIcon from "../../../../Shared/FlagIcon";
import UploadAvatar from "./UploadAvatar";

interface ICategoryBasicFormProps {
  id?: string;
  name?: LocalizedText[];
  description?: LocalizedText[];

  image?: string | null;

  enabled?: boolean;
  order?: number;
  parent?: string;

  isLoading: boolean;

  onSubmit: (category: ICategoryBasicFormValues) => void;

  // onSave: (
  //   values: IUserBasicFormValues,
  //   onRequestComplete: () => void,
  //   setError: (errors: FormikErrors<IUserBasicFormValues>) => void
  // ) => void;
}

export interface ICategoryBasicFormValues {
  id?: string;
  name: LocalizedText[];
  description: LocalizedText[];
  imageBase64?: string;
  image?: string;

  enabled?: boolean;
  order: number;
  parent: string;
}

const CategoryBasicForm = (props: ICategoryBasicFormProps) => {
  const {
    image,
    enabled,
    name,
    order,
    parent,
    id,
    description,
    isLoading,
    onSubmit,
  } = props;

  const initialFormValues: ICategoryBasicFormValues = {
    name: [
      {
        text: "",
        default: true,
        locale: "pl",
      },
      {
        text: "",
        default: false,
        locale: "en",
      },
    ],
    description: [
      {
        text: "",
        default: true,
        locale: "pl",
      },
      {
        text: "",
        default: false,
        locale: "en",
      },
    ],
    enabled: true,
    order: 1,
    parent: "",
  };

  const [formData, setFormData] = useState<ICategoryBasicFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      image: initialFormValues.image,
      enabled,
      order: order || initialFormValues.order,
      parent: parent || initialFormValues.parent,
      id,
      description: description || initialFormValues.description,
      name: name || initialFormValues.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, enabled, order, parent, id, description, name]);
  const formikRef = useRef<FormikValues>();

  const LocalizedSchema = Yup.object({
    text: Yup.string().required("Pole wymagane"),
  });
  const NewCategorySchema = Yup.object().shape({
    name: Yup.array().of(LocalizedSchema),
    description: Yup.array().of(LocalizedSchema),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Dane kategorii">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={NewCategorySchema}
          onSubmit={(
            values: ICategoryBasicFormValues,
            { resetForm, setErrors }
          ) => {
            console.log(values);
            setEditRequest(false);
            onSubmit(values);
            // onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <UploadAvatar
                    imageUrl={image || ""}
                    onChange={(imageUrl) => {
                      formProps.setFieldValue("imageBase64", imageUrl);
                      if (imageUrl === "") {
                        formProps.setFieldValue("image", null);
                      }
                    }}
                  />
                </Col>
                {formProps.values.name.map(
                  (localizedText: LocalizedText, index: number) => {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <Space size="small">
                              <Text>Nazwa</Text>
                              <FlagIcon locale={localizedText.locale} />
                            </Space>
                          }
                          name={`name.${index}.text`}
                          labelCol={{ span: 24 }}
                          rules={[{ required: true }]}
                        >
                          <Input name={`name.${index}.text`} />
                        </Form.Item>
                      </Col>
                    );
                  }
                )}
                {formProps.values.description.map(
                  (localizedText: LocalizedText, index: number) => {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <Space size="small">
                              <Text>Nazwa</Text>
                              <FlagIcon locale={localizedText.locale} />
                            </Space>
                          }
                          name={`description.${index}.text`}
                          labelCol={{ span: 24 }}
                          rules={[{ required: true }]}
                        >
                          <ReactQuill
                            onBlur={() =>
                              formProps.setFieldTouched(
                                `description.${index}.text`
                              )
                            }
                            theme="snow"
                            value={localizedText.text}
                            onChange={(change) =>
                              formProps.setFieldValue(
                                `description.${index}.text`,
                                change
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                )}
                <Col span={24}>
                  <Form.Item
                    label="Kolejność"
                    name="order"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="order" type="number" step="1" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Wyświetlaj"
                    name="enabled"
                    labelCol={{ span: 24 }}
                  >
                    <Switch name="enabled" />
                  </Form.Item>
                </Col>
              </Row>

              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

CategoryBasicForm.defaultProps = {
  id: undefined,
  parent: null,
  image: null,
  name: [
    {
      text: "",
      default: true,
      locale: "pl",
    },
    {
      text: "",
      default: false,
      locale: "en",
    },
  ],
  description: [
    {
      text: "",
      default: true,
      locale: "pl",
    },
    {
      text: "",
      default: false,
      locale: "en",
    },
  ],
  enabled: true,
  order: 1,
};

export default CategoryBasicForm;
