import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Helmet } from "react-helmet";
import moment from "moment";
import "moment/locale/pl";

import Login from "./components/Auth/Login";
import "./app.scss";
import PrivateRoute from "./components/PrivateRoute";
import DashboardBase from "./components/DashboardBase";
import DashboardPage from "./components/Pages/Dashboard";

import store from "./reducers/store";

import ScrollToTop from "./components/Shared/ScrollToTop";

import AudioService from "./services/audio-service";

import LocalEditPage from "./components/Pages/Locals/edit";

import CategoriesPage from "./components/Pages/Categories";
import NewCategoryPage from "./components/Pages/Categories/new";
import EditCategoryPage from "./components/Pages/Categories/edit";
import ProductsPage from "./components/Pages/Products";

import EditProductPage from "./components/Pages/Products/edit";
import NewProductPage from "./components/Pages/Products/new";
import OrdersPage from "./components/Pages/Orders";
import OrderDetailPage from "./components/Pages/Orders/detail";

moment.locale("pl");

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <Router>
        <Helmet title="ABS SHOP" defer={false} />
        <ScrollToTop />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/" exact>
            <PrivateRoute>
              <DashboardBase title="Strona główna" subtitle="Panel zarządzania">
                <DashboardPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/categories" exact>
            <PrivateRoute>
              <DashboardBase title="Ustawienia" subtitle="Panel zarządzania">
                <CategoriesPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/categories/create" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <NewCategoryPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>
          <Route path="/categories/:id/edit" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <EditCategoryPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/products" exact>
            <PrivateRoute>
              <DashboardBase title="Produkty" subtitle="Panel zarządzania">
                <ProductsPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/products/create" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <NewProductPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>
          <Route path="/products/:id/edit" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <EditProductPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/orders" exact>
            <PrivateRoute>
              <DashboardBase title="Zamówienia" subtitle="Panel zarządzania">
                <OrdersPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/orders/:id" exact>
            <PrivateRoute>
              <DashboardBase
                title="Szczegóły zamówienia"
                subtitle="Szczegóły zamówienia"
              >
                <OrderDetailPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>

          <Route path="/locals/edit/:id/" exact>
            <PrivateRoute>
              <DashboardBase
                title="Edycja restauracji"
                subtitle="Panel zarządzania"
              >
                <LocalEditPage />
              </DashboardBase>
            </PrivateRoute>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
