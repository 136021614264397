import { FormikErrors } from "formik";

export enum AccountType {
  "Użytkownik" = 1,
  "Administrator",
}

export enum AccountTypeWithHighlight {
  "Użytkownik" = 1,
  "Administrator",
  "Opiekun działu",
  "Przydzielony",
}
export interface INewUserFormProps {
  visible: boolean;
  isRequest: boolean;
  tags: any[];
  onCreate: (
    values: INewUserFormValues,
    resetForm: () => void,
    setError: (errors: FormikErrors<INewUserFormValues>) => void
  ) => void;
  onCancel: () => void;
}

export interface INewLocalFormProps {
  visible: boolean;
  isRequest: boolean;
  tags: any[];
  onCreate: (
    values: INewLocalFormValues,
    resetForm: () => void,
    setError: (errors: FormikErrors<INewLocalFormValues>) => void
  ) => void;
  onCancel: () => void;
}

export interface INewOrderFormProps {
  visible: boolean;
  isRequest: boolean;
  address: any;
  onCreate: (
    values: INewOrderFormValues,
    resetForm: () => void,
    setError: (errors: FormikErrors<INewOrderFormValues>) => void
  ) => void;
  onCancel: () => void;
}
export interface INewUserFormValues {
  email: string;
  name: string;
  surname: string;
  password: string;
  tag: any[] | null;
  phone: string;
}

export interface INewLocalFormValues {
  email: string | null;
  name: string | null;
  surname: string | null;
  tag: any[];
  phone: string | null;
  typeAccount: string | number;
  state: string | null;
  city: string | null;
  street: string | null;
  streetNumber: string | null;
  deliveryPrice: number | null;
  password: string;
}

export interface INewOrderFormValues {
  phone: string | null;

  state: string | null;
  city: string | null;
  street: string | null;
  streetNumber: string | null;
  totalPrice: number | null;
  notes: string | null;
  takeAway: number | null;
  payment: number | null;
}

export interface IUserBasicFormValues {
  email: string | null;
}

export interface ILocalBasicFormValues {
  email: string | null;
  name: string | null;
  surname: string | null;
  tag: any[];
  phone: string | null;
  typeAccount: string | number;
  state: string | null;
  city: string | null;
  street: string | null;
  streetNumber: string | null;
  deliveryPrice: number | null;
}
export interface IPosLocalBasicFormValues {
  deliveryPrice: number | null;
}

export interface IUserPasswordForm {
  password: string;
}

export interface IUserPasswordFormValues {
  password: string;
}

export interface IUserSettingsForm {
  minTime: number;
  multTime: number;
  autostartAfterTime: number;
  disableEmail: boolean;
}

export interface IUserSettingsFormValues {
  minTime: number;
  multTime: number;
  autostartAfterTime: number;
  disableEmail: boolean;
}

export interface IUserHolidayFormValues {
  holidaysFrom: Date | null;
  holidaysUntil: Date | null;
  holidaysReason: string | null;
  onHolidays: boolean;
}

export interface IUserPermissionForm {
  role: number[];
  allowedAssignionTag: any[];
}

export interface IUserPermissionFormValues {
  role: number[];
  allowedAssignionTag: any[];
}
