import React, { PropsWithChildren } from "react";
import {
  Button,
  Image,
  Space,
  Table,
  TableColumnsType,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getDefaultLocalizedText,
  timestampToDate,
} from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";
import { Order, OrderInvoice, OrderItem } from "../../../types/order";
import { Product } from "../../../types/Product";
import emptyImage from "../../../assets/images/no-image.png";
import { LocalizedText } from "../../../types/LocalizedText";
import { ProductAttribute } from "../../../types/ProductAttribute";

const { TabPane } = Tabs;

interface IOrderProductsListProps {
  items: OrderItem[];
  isRequest: boolean;
}
const OrderProductsList = (
  props: PropsWithChildren<IOrderProductsListProps>
): JSX.Element => {
  const { items, isRequest } = props;

  const isMobile = useIsMobile();

  const renderPaymentStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return <Tag color="orange">Oczekuje</Tag>;
      case "rejected":
        return <Tag color="warning">Odrzucono</Tag>;
      case "cancelled":
        return <Tag color="error">Anulowano</Tag>;
      case "error":
        return <Tag color="red">Błąd</Tag>;
      case "settled":
        return <Tag color="green">Zapłacone</Tag>;
      default:
        return <Tag color="gray">Nieznany status</Tag>;
    }
  };

  const renderOrderStatus = (status: number) => {
    switch (status) {
      case 1:
        return <Tag color="orange">Nowe</Tag>;
      case 2:
        return <Tag color="blue">W trakcie realizacji</Tag>;
      case 3:
        return <Tag color="magenta">Wysłane</Tag>;
      case 4:
        return <Tag color="green">Zakończone</Tag>;
      default:
        return <Tag color="gray">Nieznany status</Tag>;
    }
  };
  const columns: TableColumnsType<OrderItem> = [
    {
      title: "Nr",
      dataIndex: "id",
      className: "table__column--name",
      width: 50,
      key: "id",
      render: (id: string, data, index) => {
        return index + 1;
      },
      ellipsis: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "",
      dataIndex: "product",
      key: "product-item",
      render: (product: Product) => {
        const { image } = product;
        const imageSrc = image
          ? process.env.REACT_APP_MEDIA_URL + image
          : emptyImage;
        return <Image src={imageSrc} />;
      },
      width: 60,
    },
    {
      title: "Nazwa",
      dataIndex: "product",
      className: "table__column--name",
      key: "product-name",
      render: (product: Product) => {
        if (!product.name.length) return "";

        return getDefaultLocalizedText(product.name);
      },
      ellipsis: true,
      sorter: (a: OrderItem, b: OrderItem) => {
        if (!a.product.name || !b.product.name) return -1;
        const aName = `${getDefaultLocalizedText(a.product.name)} `;
        const bName = `${getDefaultLocalizedText(b.product.name)} `;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Wariant",
      dataIndex: "attribute",
      className: "table__column--name",
      key: "atribute",
      render: (attribute: ProductAttribute) => {
        if (!attribute.name.length) return "";

        return getDefaultLocalizedText(attribute.name);
      },
      ellipsis: true,

      showSorterTooltip: false,
    },
    {
      title: "Ilość",
      dataIndex: "quantity",
      className: "table__column--name",
      key: "quantity",
      render: (quantity: number) => {
        return quantity;
      },
      ellipsis: true,

      showSorterTooltip: false,
    },
  ];

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={items}
      className="categories-page__table table"
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default OrderProductsList;
