import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Divider, Row, Tabs } from "antd";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import CategoriesService from "../../../services/categories-service";
import { Category } from "../../../types/Category";

import { openNotificationWithIcon } from "../../../utils/common";
import ProductBasicForm, { IProductBasicFormValues } from "./Forms/BasicForm";
import ProductsService from "../../../services/products-service";
import { Product } from "../../../types/Product";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}

const EditProductPage = (): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();

  const [product, setProduct] = useState<Product | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [isCategoriesRequest, setIsCategoriesRequest] = useState<boolean>(
    false
  );

  const fetchProduct = (categoryId: string) => {
    setIsRequest(true);
    ProductsService.getProduct(id)
      .then(({ data }) => {
        setProduct(data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const fetchCategories = () => {
    setIsCategoriesRequest(true);
    CategoriesService.getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .finally(() => {
        setIsCategoriesRequest(false);
      });
  };

  const handleSave = (data: IProductBasicFormValues) => {
    setIsRequest(true);

    if (data.id)
      ProductsService.editProduct(data.id, data)
        .then((response) => {
          openNotificationWithIcon("success", "Produkt zapisany");
        })
        .finally(() => setIsRequest(false));
  };

  useEffect(() => {
    fetchCategories();
    if (id) fetchProduct(id);
    // eslint-disable-next-line
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="user-edit-page">
        <MetaTitle title="Nowa kategoria" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      {product && (
                        <ProductBasicForm
                          attributes={product?.attributes}
                          categories={categories}
                          isLoading={isRequest || isCategoriesRequest}
                          name={product?.name}
                          enabled={product?.enabled}
                          category={product?.category}
                          id={product?.id}
                          order={product?.order}
                          description={product?.description}
                          image={
                            product?.image
                              ? process.env.REACT_APP_MEDIA_URL + product.image
                              : null
                          }
                          onSubmit={(data: IProductBasicFormValues) =>
                            handleSave(data)
                          }
                        />
                      )}
                      <Divider />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default EditProductPage;
