import { Badge, Button, Divider, Dropdown, Menu, Space, Tag } from "antd";
import Text from "antd/es/typography/Text";
import {
  BookOutlined,
  CheckOutlined,
  DownOutlined,
  LoadingOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { openNotificationWithIcon } from "../../../utils/common";

interface IProps {
  status: number;
  onSubmit: (status: number, onRequestComplete: () => void) => void;
  type: string;
}
const StatusDropdown = (props: IProps) => {
  const { status, onSubmit, type } = props;
  const [submitting, setSubmitting] = useState(false);
  const [priority, setPriority] = useState(status);

  useEffect(() => {
    setPriority(status);
  }, [status]);

  const changeStatus = (id: number) => {
    setSubmitting(true);
    setPriority(id);
    onSubmit(id, () => {
      setSubmitting(false);
      openNotificationWithIcon("success", "Status zmieniony");
    });
  };

  const renderOrderStatusName = (statusId: number) => {
    switch (statusId) {
      case 1:
        return "Nowe";
      case 2:
        return "W trakcie realizacji";
      case 3:
        return "Wyłane";
      case 4:
        return "Zakończone";
      default:
        return "Nieznany status";
    }
  };

  const renderOrderStatusColor = (statusId: number) => {
    switch (statusId) {
      case 1:
        return "orange";
      case 2:
        return "blue";
      case 3:
        return "magenta";
      case 4:
        return "green";
      default:
        return "gray";
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        className="ant-dropdown-menu-item"
        onClick={() => changeStatus(1)}
      >
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>Nowy</Text>
              {status === 1 && <CheckOutlined />}
            </Space>
          }
          color="orange"
        />
      </Menu.Item>

      <Menu.Item
        key="2"
        className="ant-dropdown-menu-item"
        onClick={() => changeStatus(2)}
      >
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>W trakcie realizacji</Text>
              {status === 2 && <CheckOutlined />}
            </Space>
          }
          color="blue"
        />
      </Menu.Item>
      <Menu.Item
        key="3"
        className="ant-dropdown-menu-item"
        onClick={() => changeStatus(3)}
      >
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>Wysłane</Text>
              {status === 3 && <CheckOutlined />}
            </Space>
          }
          color="magenta"
        />
      </Menu.Item>
      <Menu.Item
        key="4"
        className="ant-dropdown-menu-item"
        onClick={() => changeStatus(4)}
      >
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>Zakończone</Text>
              {status === 4 && <CheckOutlined />}
            </Space>
          }
          color="green"
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={!submitting ? ["click"] : []}
      getPopupContainer={
        type !== "text"
          ? (trigger) => trigger.parentElement as HTMLElement
          : undefined
      }
    >
      <Button type={type === "text" ? "text" : "default"}>
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>{renderOrderStatusName(status)}</Text>
              {!submitting && <DownOutlined style={{ fontSize: 10 }} />}

              {submitting && <LoadingOutlined spin style={{ fontSize: 10 }} />}
            </Space>
          }
          color={renderOrderStatusColor(status)}
        />
      </Button>
    </Dropdown>
  );
};

export default StatusDropdown;
