import React, { PropsWithChildren } from "react";
import {
  Avatar,
  Button,
  Image,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnsType,
  TableColumnType,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import {
  getDefaultLocalizedText,
  makeAvatarName,
  stringToColour,
} from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";
import { Category } from "../../../types/Category";
import { LocalizedText } from "../../../types/LocalizedText";
import emptyImage from "../../../assets/images/no-image.png";

const { TabPane } = Tabs;

interface ICategoriesListProps {
  categories: Category[];
  isRequest: boolean;
  onDelete: (id: string, callback: () => void) => void;
}
const CategoriesList = (
  props: PropsWithChildren<ICategoriesListProps>
): JSX.Element => {
  const { categories, isRequest, onDelete } = props;

  const isMobile = useIsMobile();

  const renderTag = (enabled: boolean) => {
    if (enabled) return <Tag color="green">Tak</Tag>;
    return <Tag color="red">Nie</Tag>;
  };

  const columns: TableColumnsType<Category> = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      render: (image: string, category: Category) => {
        const imageSrc = image
          ? process.env.REACT_APP_MEDIA_URL + image
          : emptyImage;
        return <Image src={imageSrc} />;
      },
      width: 60,
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      className: "table__column--name",
      key: "name",
      render: (name: LocalizedText[]) => {
        if (!name.length) return "";

        return getDefaultLocalizedText(name);
      },
      ellipsis: true,
      sorter: (a: Category, b: Category) => {
        if (!a.name || !b.name) return -1;
        const aName = `${getDefaultLocalizedText(a.name)} `;
        const bName = `${getDefaultLocalizedText(b.name)} `;
        return aName.localeCompare(bName);
      },
      showSorterTooltip: false,
    },
    {
      title: "Kolejność wyświetlania",
      dataIndex: "order",
      className: "table__column--name",
      key: "order",
      render: (order: string, category: Category) => {
        if (!order) return "";

        return order;
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "Wyświetlana",
      dataIndex: "enabled",
      className: "table__column--name",
      key: "enabled",
      render: (enabled: boolean, category: Category) => {
        return renderTag(enabled);
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },

    {
      title: "Akcje",
      key: "action",
      className: "table__column--actions",
      width: 100,
      fixed: "right" as const,
      render: (text: string, category: Category) => {
        return (
          <Space size="middle">
            <Tooltip title="Edytuj" mouseEnterDelay={1}>
              <Link to={`/categories/${category.id}/edit`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Tooltip>

            <RemoveRecord
              title="Czy napewno chcesz usunąć kategorię?"
              onConfirm={(callbackHidePopover: () => void) =>
                onDelete(category.id, callbackHidePopover)
              }
              okText="Usuń"
              cancelText="Anuluj"
              tooltipText="Usuń"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={categories}
      className="categories-page__table table"
      scroll={{ x: isMobile ? 0 : 1000 }}
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default CategoriesList;
