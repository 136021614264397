import React, { useEffect, useState } from "react";
import { Button, Card, Col, Grid, Row } from "antd";
import {
  FieldTimeOutlined,
  HddOutlined,
  HourglassOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import { FormikErrors } from "formik";
import { AxiosError, AxiosResponse } from "axios";
import StatisticsBox from "../../Shared/StatisticBox";
import ProjectsWorkTime from "../../Shared/ProjectsWorkTime";
import UsersOnline from "../../Shared/UsersOnline";
import ActiveTasks from "../../Shared/ActiveTasks";

import DashboardService from "../../../services/dashboard-service";
import {
  formatSeconds,
  formatSecondsToFootballMatch,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import { MetaTitle } from "../../Shared/MetaTitle";
import { createTicket } from "../../../actions/tickets";
import { AppState } from "../../../reducers";
import UsersWorktimes from "../../Shared/UsersWorktimes";
import Can from "../../Shared/Can";
import NewLocal from "../Locals/new";
import OrdersService from "../../../services/orders-service";
import NewOrderForm from "./newOrder";
import EditTime from "./editTime";

const DashboardPage = (props: any): JSX.Element => {
  const [stats, setStats] = useState<any>(null);
  const [isStatsLoading, setStatsLoading] = useState(false);

  const [worktimes, setWorktimes] = useState(null);
  const [isWorktimesLoading, setWorktimesLoading] = useState(false);

  const [users, setUsers] = useState(null);
  const [isUsersLoading, setUsersLoading] = useState(false);

  const [restaurant, setRestaurant] = useState<any>(null);
  const [isActiveTasksLoading, setActiveTasksLoading] = useState(false);

  const { displayNotification } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editTime, setEditTime] = useState<{
    id: string;
    take_away_time: number;
  } | null>(null);
  const [refresh, setRefresh] = useState<number>(0);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);
  useEffect(() => {}, []);

  const getRestaurant = () => {
    OrdersService.getRestaurant().then((res) => {
      setRestaurant(res.data);
    });
  };

  return (
    <div className="dashboard-page">
      <MetaTitle title="Kokpit" displayBadge={displayNotification} />

      <Row gutter={16} className="dashboard-page__worktime">
        <Can type="is_user">
          <Col span={24}>
            <div className="users-page__header-actions" />
          </Col>
        </Can>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  createTicketAction: createTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
