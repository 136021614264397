import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Breadcrumb, Col, Row, Spin } from "antd";
import {
  HomeOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";

const Breadcrumbs = (props: any): JSX.Element => {
  const breadcrumbNameMap: any = {
    "/": "Home",
    "/categories": "Kategorie",
    "/categories/create": "Nowa kategoria",
    "/categories/:id/edit": "Edycja kategorii",
    "/products/:id/edit": "Edycja produktów",
    "/products": "Produkty",
    "/products/create/": "Nowy produkt",
    "/orders": "Zamówienia",
    "/orders/:id": "Szczegóły",
  };

  const { match } = props;
  const pathSnippets = match.path.split("/").filter((i: any) => i);
  const urlSnippets = match.url.split("/").filter((i: any) => i);

  const extraBreadcrumbItems = pathSnippets.map((_: any, index: number) => {
    const path = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const url = `/${urlSnippets.slice(0, index + 1).join("/")}`;
    if (breadcrumbNameMap[path]) {
      if (pathSnippets.length === index + 1) {
        return (
          <Breadcrumb.Item key={url}>
            <Text ellipsis style={{ maxWidth: 300 }}>
              {breadcrumbNameMap[path]}
            </Text>
          </Breadcrumb.Item>
        );
      }

      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>
            <Text ellipsis style={{ maxWidth: 300 }}>
              {breadcrumbNameMap[path]}
            </Text>
          </Link>
        </Breadcrumb.Item>
      );
    }
    return null;
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
        <span> Strona główna</span>
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <Row gutter={16} className="breadcrumbs">
      <Col span={24}>
        <Breadcrumb separator={<RightOutlined />}>{breadcrumbItems}</Breadcrumb>
      </Col>
    </Row>
  );
};

export default withRouter(Breadcrumbs);
