import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Skeleton, Tabs, Tag } from "antd";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import CategoriesService from "../../../services/categories-service";
import { ICategoryBasicFormValues } from "./Forms/BasicForm";
import {
  formatMoney,
  openNotificationWithIcon,
  timestampToDate,
} from "../../../utils/common";
import { Order } from "../../../types/order";
import OrdersService from "../../../services/orders-service";
import OrderProductsList from "./OrderProductsList";
import StatusDropdown from "./StatusDropdown";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}

const OrderDetailPage = (): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();

  const [order, setOrder] = useState<Order>();
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const fetchOrder = (orderId: string) => {
    setIsRequest(true);
    OrdersService.getOrder(id)
      .then(({ data }) => {
        setOrder(data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const updateOrder = (status: number, callback: () => void) => {
    setIsRequest(true);
    OrdersService.editOrder(id, { status })
      .then(({ data }) => {
        setOrder(data);
      })
      .finally(() => {
        setIsRequest(false);
        callback();
      });
  };

  const handleSave = (data: ICategoryBasicFormValues) => {
    setIsRequest(true);

    if (data.id)
      CategoriesService.editCategory(data.id, data)
        .then((response) => {
          openNotificationWithIcon("success", "Kategoria zapisana");
        })
        .finally(() => setIsRequest(false));
  };

  useEffect(() => {
    if (id) fetchOrder(id);
    // eslint-disable-next-line
  }, []);

  const renderPaymentStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return <Tag color="orange">Oczekuje</Tag>;
      case "rejected":
        return <Tag color="warning">Odrzucono</Tag>;
      case "cancelled":
        return <Tag color="error">Anulowano</Tag>;
      case "error":
        return <Tag color="red">Błąd</Tag>;
      case "settled":
        return <Tag color="green">Zapłacone</Tag>;
      default:
        return <Tag color="gray">Nieznany status</Tag>;
    }
  };

  return (
    <Can renderError type="is_admin">
      <div className="order-detail-page">
        <MetaTitle title="Szczegóły zamówienia" displayBadge={false} />
        <Row gutter={16}>
          <Col span={16}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
              title="Zamówione produkty"
            >
              <OrderProductsList
                items={order?.items || []}
                isRequest={isRequest}
              />
            </Card>
          </Col>

          <Col span={8}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
              title="Szczegóły zamówienia"
            >
              <Skeleton loading={isRequest}>
                <Row gutter={16} align="middle" justify="center">
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <p>
                      <strong>Utworzono:</strong>{" "}
                      {timestampToDate(order?.created || 0, "dd.MM.yyyy HH:mm")}
                    </p>
                    <p>
                      <strong>Suma zamówienia:</strong>{" "}
                      {formatMoney(order?.sum || 0)}
                    </p>
                    <p>
                      <strong>Płatność:</strong>{" "}
                      {renderPaymentStatusTag(
                        order?.payment_status || "pending"
                      )}
                    </p>

                    <p>
                      <strong>Status zamówienia:</strong>{" "}
                      <StatusDropdown
                        status={order?.status || 0}
                        onSubmit={(status: number, callback) =>
                          updateOrder(status, callback)
                        }
                        type="text"
                      />
                    </p>
                  </Col>
                </Row>
              </Skeleton>
            </Card>

            <Card
              bordered={false}
              style={{ width: "100%", marginTop: 16 }}
              size={isMobile ? "small" : "default"}
              title="Dane płatnika"
            >
              <Skeleton loading={isRequest}>
                <Row gutter={16} align="middle" justify="center">
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <p>
                      <strong>Imię i nazwisko:</strong>{" "}
                      {order?.customer.firstName} {order?.customer.lastName}
                    </p>
                    <p>
                      <strong>Email:</strong> {order?.customer.email}
                    </p>
                  </Col>
                </Row>
              </Skeleton>
            </Card>

            <Card
              bordered={false}
              style={{ width: "100%", marginTop: 16 }}
              size={isMobile ? "small" : "default"}
              title="Dane do dostawy"
            >
              <Skeleton loading={isRequest}>
                <Row gutter={16} align="middle" justify="center">
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <p>
                      <strong>Adres:</strong> {order?.delivery.street}{" "}
                      {order?.delivery.street_number}
                    </p>
                    <p>
                      <strong>Miasto:</strong> {order?.delivery.city}{" "}
                      {order?.delivery.post_code}
                    </p>
                    <p>
                      <strong>Telefon:</strong> {order?.delivery.phone}
                    </p>
                  </Col>
                </Row>
              </Skeleton>
            </Card>

            <Card
              bordered={false}
              style={{ width: "100%", marginTop: 16 }}
              size={isMobile ? "small" : "default"}
              title="Dane do faktury"
            >
              <Skeleton loading={isRequest}>
                <Row gutter={16} align="middle" justify="center">
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                    <p>
                      <strong>Nazwa firmy:</strong> {order?.invoice.name}
                    </p>
                    <p>
                      <strong>NIP:</strong> {order?.invoice.nip}
                    </p>
                    <p>
                      <strong>Adres:</strong> {order?.invoice.street}{" "}
                      {order?.invoice.street_number}
                    </p>
                    <p>
                      <strong>Miasto:</strong> {order?.invoice.city}{" "}
                      {order?.invoice.post_code}
                    </p>
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default OrderDetailPage;
