import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Divider, Row, Tabs } from "antd";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import CategoriesService from "../../../services/categories-service";
import { Category } from "../../../types/Category";
import CategoryBasicForm, { ICategoryBasicFormValues } from "./Forms/BasicForm";
import { openNotificationWithIcon } from "../../../utils/common";
import ProductsService from "../../../services/products-service";
import { Product } from "../../../types/Product";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}

const EditCategoryPage = (): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();

  const [category, setCategory] = useState<Category | null>(null);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const fetchCategory = (categoryId: string) => {
    setIsRequest(true);
    CategoriesService.getCategory(id)
      .then(({ data }) => {
        setCategory(data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const handleSave = (data: ICategoryBasicFormValues) => {
    setIsRequest(true);

    if (data.id)
      CategoriesService.editCategory(data.id, data)
        .then((response) => {
          openNotificationWithIcon("success", "Kategoria zapisana");
        })
        .finally(() => setIsRequest(false));
  };

  useEffect(() => {
    if (id) fetchCategory(id);
    // eslint-disable-next-line
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="user-edit-page">
        <MetaTitle title="Edytuj produkt" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      {category && (
                        <CategoryBasicForm
                          isLoading={isRequest}
                          name={category?.name}
                          enabled={category?.enabled}
                          parent={category?.parent}
                          id={category?.id}
                          order={category?.order}
                          description={category?.description}
                          image={
                            category?.image
                              ? process.env.REACT_APP_MEDIA_URL + category.image
                              : null
                          }
                          onSubmit={(data: ICategoryBasicFormValues) =>
                            handleSave(data)
                          }
                        />
                      )}
                      <Divider />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default EditCategoryPage;
