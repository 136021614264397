import React, { PropsWithChildren } from "react";
import {
  Button,
  Space,
  Table,
  TableColumnsType,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { timestampToDate } from "../../../utils/common";
import RemoveRecord from "../../Shared/RemoveRecord";
import useIsMobile from "../../../hooks/useIsMobile";
import { Order, OrderInvoice } from "../../../types/order";

const { TabPane } = Tabs;

interface IOrdersListProps {
  orders: Order[];
  isRequest: boolean;
  onDelete: (id: string | number, callback: () => void) => void;
}
const OrdersList = (
  props: PropsWithChildren<IOrdersListProps>
): JSX.Element => {
  const { orders, isRequest, onDelete } = props;

  const isMobile = useIsMobile();

  const renderTag = (enabled: boolean) => {
    if (enabled) return <Tag color="green">Tak</Tag>;
    return <Tag color="red">Nie</Tag>;
  };

  const renderPaymentStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return <Tag color="orange">Oczekuje</Tag>;
      case "rejected":
        return <Tag color="warning">Odrzucono</Tag>;
      case "cancelled":
        return <Tag color="error">Anulowano</Tag>;
      case "error":
        return <Tag color="red">Błąd</Tag>;
      case "settled":
        return <Tag color="green">Zapłacone</Tag>;
      default:
        return <Tag color="gray">Nieznany status</Tag>;
    }
  };

  const renderOrderStatus = (status: number) => {
    switch (status) {
      case 1:
        return <Tag color="orange">Nowe</Tag>;
      case 2:
        return <Tag color="blue">W trakcie realizacji</Tag>;
      case 3:
        return <Tag color="magenta">Wysłane</Tag>;
      case 4:
        return <Tag color="green">Zakończone</Tag>;
      default:
        return <Tag color="gray">Nieznany status</Tag>;
    }
  };
  const columns: TableColumnsType<Order> = [
    {
      title: "Nr",
      dataIndex: "id",
      className: "table__column--name",
      width: 50,
      key: "id",
      render: (id: string, data, index) => {
        return index + 1;
      },
      ellipsis: true,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "Id",
      dataIndex: "id",
      className: "table__column--namex",
      key: "id",
      width: 300,
      render: (id: string) => {
        return id;
      },
      ellipsis: true,
      sorter: (a: Order, b: Order) => {
        if (!a.id || !b.id) return -1;

        return a.id.localeCompare(b.id);
      },
      showSorterTooltip: false,
    },
    {
      title: "Data utworzenia",
      dataIndex: "created",
      className: "table__column--name",
      key: "created",
      render: (created: number) => {
        return timestampToDate(created, "dd.MM.yyyy HH:mm");
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "Klient",
      dataIndex: "invoice",
      className: "table__column--name",
      key: "invoice-name",
      render: (invoice: OrderInvoice) => {
        return invoice.name;
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "nip",
      dataIndex: "invoice",
      className: "table__column--name",
      key: "invoice-nip",
      render: (invoice: OrderInvoice) => {
        return invoice.nip;
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "Platność",
      dataIndex: "payment_status",
      className: "table__column--name",
      key: "payment_status",
      render: (payment_status: string) => {
        return renderPaymentStatusTag(payment_status);
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "Status zamówienia",
      dataIndex: "status",
      className: "table__column--name",
      key: "status",
      render: (status: number) => {
        return renderOrderStatus(status);
      },
      ellipsis: true,
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: "Akcje",
      key: "action",
      className: "table__column--actions",
      width: 100,
      fixed: "right" as const,
      render: (text: string, order: Order) => {
        return (
          <Space size="middle">
            <Tooltip title="Zobacz zamówienie" mouseEnterDelay={1}>
              <Link to={`/orders/${order.id}`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>

            <RemoveRecord
              title="Czy napewno chcesz usunąć zamówienie?"
              onConfirm={(callbackHidePopover: () => void) =>
                onDelete(order.id, callbackHidePopover)
              }
              okText="Usuń"
              cancelText="Anuluj"
              tooltipText="Usuń"
            />
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      size={isMobile ? "small" : "middle"}
      locale={{ emptyText: "Brak danych" }}
      dataSource={orders}
      className="categories-page__table table"
      scroll={{ x: isMobile ? 0 : 1000 }}
      columns={columns}
      pagination={{ position: ["bottomRight"] }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default OrdersList;
