import React, { useEffect, useState } from "react";

import { Button, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import CategoriesList from "./list";
import CategoriesService from "../../../services/categories-service";
import { Category } from "../../../types/Category";
import { openNotificationWithIcon } from "../../../utils/common";
import { ICategoryBasicFormValues } from "./Forms/BasicForm";

const CategoriesPage = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleFetch = () => {
    setIsRequest(true);
    CategoriesService.getCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const handleRemove = (id: string, callback: () => void) => {
    CategoriesService.deleteCategory(id)
      .then(() => {
        handleFetch();
        openNotificationWithIcon("success", "Kategoria usunięta");
      })
      .catch(() => {})
      .finally(() => {
        callback();
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="categories-page">
        <MetaTitle title="Kategorie" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <div className="categories-page__header-actions">
              <Link to="/categories/create">
                <Button type="primary">Nowy kategoria</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <CategoriesList
                categories={categories}
                isRequest={isRequest}
                onDelete={(id, callback) => handleRemove(id, callback)}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default CategoriesPage;
