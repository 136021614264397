import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";
import { Order, UpdateOrderRequest } from "../../types/order";

class OrdersService {
  getOrders = async (): Promise<AxiosResponse<Order[]>> => {
    const response = await api.get("orders");
    return response;
  };

  getOrder = async (id: string | number): Promise<AxiosResponse<Order>> => {
    const response = await api.get(`orders/${id}`);
    return response;
  };

  editOrder = async (
    id: number | string,
    order: UpdateOrderRequest
  ): Promise<AxiosResponse> => {
    const response = await api.put(`orders/${id}`, order);
    return response;
  };

  deleteOrder = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`orders/${id}`);
    return response;
  };
}

export default new OrdersService();
