import React from "react";
import { Button, message, Upload, Modal } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import ImgCrop from "antd-img-crop";
import "antd/es/modal/style";
import "antd/es/slider/style";

interface IUploadAvatarState {
  imageUrl: string | null | any;
  loading: boolean;
  removeLoading: boolean;
}
interface IUploadAvatarProps {
  imageUrl: string | null;
  onChange: (imageUrl: string) => void;
}

class UploadAvatar extends React.Component<
  IUploadAvatarProps,
  IUploadAvatarState
> {
  constructor(props: IUploadAvatarProps) {
    super(props);
    this.state = {
      imageUrl: props.imageUrl,
      loading: false,
      removeLoading: false,
    };

    console.log(props.imageUrl, "url he");
  }

  // static getDerivedStateFromProps(
  //   props: IUploadAvatarProps,
  //   state: IUploadAvatarState
  // ) {
  //   if (props.imageUrl !== state.imageUrl) {
  //     return {
  //       imageUrl: props.imageUrl,
  //     };
  //   }
  //   return null;
  // }

  handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: false });
    }
  };

  onRequestComplete = (url?: string) => {
    const { imageUrl } = this.state;
    this.setState({
      loading: false,
      imageUrl: url || imageUrl,
    });
  };

  onRemoveRequestComplete = () => {
    this.setState({
      removeLoading: false,
    });
  };

  handleUpload = (options: RcCustomRequestOptions) => {
    const { onChange } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    onChange(this.state.imageUrl);
  };

  beforeUpload = (file: any): any => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        console.log(reader.result, "results");
        self.setState({ imageUrl: reader.result });
        resolve(file);
      };

      reader.onerror = (error) => {
        console.error("Error converting file to Base64:", error);
        resolve(false);
      };
    });
  };

  handleRemove = () => {
    const { onChange } = this.props;
    this.setState({
      removeLoading: false,
      imageUrl: "",
    });

    onChange("");
  };

  render() {
    const { loading, imageUrl, removeLoading } = this.state;

    console.log(imageUrl, "?");
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        {!loading ? <div style={{ marginTop: 8 }}>Dodaj zdjęcie</div> : null}
      </div>
    );
    return (
      <>
        <ImgCrop rotationSlider modalTitle="Edytuj avatar">
          <Upload
            name="avatar"
            listType="picture-card"
            className="upload-avatar"
            customRequest={this.handleUpload}
            showUploadList={false}
            onRemove={() => {}}
            beforeUpload={(file) => this.beforeUpload(file)}
            onChange={this.handleChange}
          >
            {imageUrl ? (
              <div className="upload-avatar">
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </ImgCrop>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {imageUrl ? (
          <Button
            icon={<DeleteOutlined />}
            onClick={this.handleRemove}
            loading={removeLoading}
          >
            Usuń zdjęcie
          </Button>
        ) : null}
      </>
    );
  }
}

export default UploadAvatar;
