// eslint-disable jsx-a11y/alt-text
import React from "react";
import pl from "../../../assets/images/flags/pl.png";
import en from "../../../assets/images/flags/en.png";

interface IProps {
  locale: string;
}
const FlagIcon = (props: IProps): JSX.Element => {
  const { locale } = props;
  switch (locale) {
    case "pl":
      // eslint-disable-next-line
      return <img src={pl} />;
      break;
    case "en":
      // eslint-disable-next-line
      return <img src={en} />;
      break;
    default:
      // eslint-disable-next-line
      return <img src={pl} />;
  }
};

export default FlagIcon;
