import React, { useEffect, useState } from "react";

import { Card, Col, Row } from "antd";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import OrdersList from "./list";
import { openNotificationWithIcon } from "../../../utils/common";
import { Order } from "../../../types/order";
import OrdersService from "../../../services/orders-service";

const OrdersPage = (): JSX.Element => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleFetch = () => {
    setIsRequest(true);
    OrdersService.getOrders()
      .then((response) => {
        setOrders(response.data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const handleRemove = (id: string | number, callback: () => void) => {
    OrdersService.deleteOrder(id)
      .then(() => {
        handleFetch();
        openNotificationWithIcon("success", "Zamówienie usunięte");
      })
      .catch(() => {})
      .finally(() => {
        callback();
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="orders-page">
        <MetaTitle title="Zamówienia" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <div className="orders-page__header-actions">
              {/* <Link to="/categories/create"> */}
              {/*  <Button type="primary">Nowy kategoria</Button> */}
              {/* </Link> */}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <OrdersList
                orders={orders}
                isRequest={isRequest}
                onDelete={(id, callback) => handleRemove(id, callback)}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default OrdersPage;
