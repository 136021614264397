import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";
import { Category } from "../../types/Category";
import { ICategoryBasicFormValues } from "../../components/Pages/Categories/Forms/BasicForm";

class CategoriesService {
  getCategories = async (): Promise<AxiosResponse<Category[]>> => {
    const response = await api.get("categories");
    return response;
  };

  getCategory = async (
    id: string | number
  ): Promise<AxiosResponse<Category>> => {
    const response = await api.get(`categories/${id}`);
    return response;
  };

  createCategory = async (
    category: Category | ICategoryBasicFormValues
  ): Promise<AxiosResponse> => {
    const response = await api.post(`categories`, category);
    return response;
  };

  editCategory = async (
    id: number | string,
    category: Category | ICategoryBasicFormValues
  ): Promise<AxiosResponse> => {
    const response = await api.put(`categories/${id}`, category);
    return response;
  };

  deleteCategory = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`categories/${id}`);
    return response;
  };
}

export default new CategoriesService();
