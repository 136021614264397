import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  RemoveRowButton,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import { Button, Card, Col, Row, Space, Spin } from "antd";
import { Field, FieldArray, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import Text from "antd/es/typography/Text";
import ReactQuill from "react-quill-2";
import "react-quill/dist/quill.snow.css";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { LocalizedText } from "../../../../../types/LocalizedText";
import FlagIcon from "../../../../Shared/FlagIcon";
import UploadAvatar from "./UploadAvatar";
import { Category } from "../../../../../types/Category";
import { ProductAttribute } from "../../../../../types/ProductAttribute";
import { getDefaultLocalizedText } from "../../../../../utils/common";

interface IProductBasicFormProps {
  id?: string;
  name?: LocalizedText[];
  description?: LocalizedText[];
  // eslint-disable-next-line react/require-default-props
  category?: string;

  attributes?: ProductAttribute[] | null;

  // eslint-disable-next-line react/no-unused-prop-types
  categories: Category[];

  image?: string | null;

  enabled?: boolean;
  order?: number;
  isLoading: boolean;

  onSubmit: (product: IProductBasicFormValues) => void;

  // onSave: (
  //   values: IUserBasicFormValues,
  //   onRequestComplete: () => void,
  //   setError: (errors: FormikErrors<IUserBasicFormValues>) => void
  // ) => void;
}

export interface IProductBasicFormValues {
  id?: string;
  name: LocalizedText[];
  description: LocalizedText[];
  imageBase64?: string;
  attributes: ProductAttribute[];
  image?: string;

  category?: string | null;

  enabled?: boolean;
  order: number;
}

const ProductBasicForm = (props: IProductBasicFormProps) => {
  const {
    image,
    enabled,
    name,
    order,
    category,
    categories,
    attributes,
    id,
    description,
    isLoading,
    onSubmit,
  } = props;

  const initialFormValues: IProductBasicFormValues = {
    name: [
      {
        text: "",
        default: true,
        locale: "pl",
      },
      {
        text: "",
        default: false,
        locale: "en",
      },
    ],
    description: [
      {
        text: "",
        default: true,
        locale: "pl",
      },
      {
        text: "",
        default: false,
        locale: "en",
      },
    ],
    enabled: true,
    order: 1,
    category: null,
    attributes: [],
  };

  const [formData, setFormData] = useState<IProductBasicFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      category,
      attributes: attributes || [],
      image: initialFormValues.image,
      enabled,
      order: order || initialFormValues.order,
      id,
      description: description || initialFormValues.description,
      name: name || initialFormValues.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, enabled, order, id, description, name]);
  const formikRef = useRef<FormikValues>();

  const LocalizedSchema = Yup.object({
    text: Yup.string().required("Pole wymagane"),
  });
  const AttributesSchema = Yup.object({
    price: Yup.number().required("Pole wymagane"),
    vat: Yup.number().required("Pole wymagane"),
    name: Yup.array().of(LocalizedSchema),
  });

  const NewProductSchema = Yup.object().shape({
    name: Yup.array().of(LocalizedSchema),
    description: Yup.array().of(LocalizedSchema),
    attributes: Yup.array().of(AttributesSchema),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Dane produktu">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={NewProductSchema}
          onSubmit={(
            values: IProductBasicFormValues,
            { resetForm, setErrors }
          ) => {
            console.log(values);
            setEditRequest(false);
            onSubmit(values);
            // onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={(formProps) => (
            <Form className="form">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <UploadAvatar
                    imageUrl={image || ""}
                    onChange={(imageUrl) => {
                      formProps.setFieldValue("imageBase64", imageUrl);
                      if (imageUrl === "") {
                        formProps.setFieldValue("image", null);
                      }
                    }}
                  />
                </Col>
                {formProps.values.name.map(
                  (localizedText: LocalizedText, index: number) => {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <Space size="small">
                              <Text>Nazwa</Text>
                              <FlagIcon locale={localizedText.locale} />
                            </Space>
                          }
                          name={`name.${index}.text`}
                          labelCol={{ span: 24 }}
                          rules={[{ required: true }]}
                        >
                          <Input name={`name.${index}.text`} />
                        </Form.Item>
                      </Col>
                    );
                  }
                )}
                {formProps.values.description.map(
                  (localizedText: LocalizedText, index: number) => {
                    return (
                      <Col span={12}>
                        <Form.Item
                          label={
                            <Space size="small">
                              <Text>Nazwa</Text>
                              <FlagIcon locale={localizedText.locale} />
                            </Space>
                          }
                          name={`description.${index}.text`}
                          labelCol={{ span: 24 }}
                          rules={[{ required: true }]}
                        >
                          <ReactQuill
                            onBlur={() =>
                              formProps.setFieldTouched(
                                `description.${index}.text`
                              )
                            }
                            theme="snow"
                            value={localizedText.text}
                            onChange={(change) =>
                              formProps.setFieldValue(
                                `description.${index}.text`,
                                change
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    );
                  }
                )}

                <FieldArray name="attributes">
                  {({ insert, remove, push }) => (
                    <Row>
                      {formProps.values?.attributes.length > 0 &&
                        formProps.values?.attributes.map((item, index) => (
                          <Row gutter={[8, 8]}>
                            <Col span={14}>
                              <Row gutter={[8, 8]}>
                                {item.name.map(
                                  (
                                    localizedText: LocalizedText,
                                    idx: number
                                  ) => {
                                    return (
                                      <Col span={12}>
                                        <Form.Item
                                          label={
                                            <Space size="small">
                                              <Text>Nazwa</Text>
                                              <FlagIcon
                                                locale={localizedText.locale}
                                              />
                                            </Space>
                                          }
                                          name={`attributes.${index}.name.${idx}.text`}
                                          labelCol={{ span: 24 }}
                                          rules={[{ required: true }]}
                                        >
                                          <Input
                                            name={`attributes.${index}.name.${idx}.text`}
                                          />
                                        </Form.Item>
                                      </Col>
                                    );
                                  }
                                )}
                              </Row>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label={
                                  <Space size="small">
                                    <Text>Cena</Text>
                                  </Space>
                                }
                                name={`attributes.${index}.price`}
                                labelCol={{ span: 24 }}
                                rules={[{ required: true }]}
                              >
                                <Input
                                  name={`attributes.${index}.price`}
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label={
                                  <Space size="small">
                                    <Text>VAT</Text>
                                  </Space>
                                }
                                name={`attributes.${index}.vat`}
                                labelCol={{ span: 24 }}
                                rules={[{ required: true }]}
                              >
                                <Select name={`attributes.${index}.vat`}>
                                  <option value={5}>5%</option>
                                  <option value={8}>8%</option>
                                  <option value={23}>23%</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <Button
                                className="form__delete-button"
                                type="default"
                                onClick={() => remove(index)}
                                icon={<DeleteOutlined />}
                              />
                            </Col>
                          </Row>
                        ))}

                      <Button
                        type="dashed"
                        onClick={() =>
                          push({
                            id: new Date().getTime(),
                            price: null,
                            vat: 23,
                            name: [
                              { text: "", locale: "pl", default: true },
                              { text: "", locale: "en", default: false },
                            ],
                          })
                        }
                        icon={<PlusOutlined />}
                      >
                        Dodaj wariant
                      </Button>
                    </Row>
                  )}
                </FieldArray>

                <Col span={24}>
                  <Form.Item
                    label="Kategoria"
                    name="order"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Select name="category">
                      {categories.map((cat) => (
                        <option value={cat.id}>
                          {getDefaultLocalizedText(cat.name)}
                        </option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Kolejność"
                    name="order"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="order" type="number" step="1" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Wyświetlaj"
                    name="enabled"
                    labelCol={{ span: 24 }}
                  >
                    <Switch name="enabled" />
                  </Form.Item>
                </Col>
              </Row>

              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

ProductBasicForm.defaultProps = {
  id: undefined,
  image: null,
  name: [
    {
      text: "",
      default: true,
      locale: "pl",
    },
    {
      text: "",
      default: false,
      locale: "en",
    },
  ],
  description: [
    {
      text: "",
      default: true,
      locale: "pl",
    },
    {
      text: "",
      default: false,
      locale: "en",
    },
  ],
  enabled: true,
  order: 1,
  attributes: [],
  category: null,
};

export default ProductBasicForm;
