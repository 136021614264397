// TODO: display errors while name is not unique (backend/front - create/edit)
import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Col, Divider, Empty, Row, Spin, Tabs } from "antd";
import { RcFile } from "antd/es/upload";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  bulkAddTicketGroupMembership,
  editUser,
  getTags,
  getTicketGroupMembership,
  getUser,
  removeAvatar,
  setUser,
  uploadAvatar,
} from "../../../actions/users";
import { getRolesGroups } from "../../../actions/roles";
import UserBasicForm from "./EditUserForm/BasicForm";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import UserPasswordForm from "./EditUserForm/PasswordForm";
import { RoleGroup, RoleModel } from "../../../reducers/roles";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";

import {
  bulkAddTicketGroupMembers,
  getTicketGroups,
  removeTicketGroupMember,
} from "../../../actions/ticketgroups";

import UserAddMembershipForm, {
  IUserAddMembership,
  IUserAddMembershipFormValues,
} from "./EditUserForm/AddMembershipForm";
import { setCurrentEditedUser } from "../../../actions/global";

import { editLocal, getLocal, setLocal } from "../../../actions/locals";
import PosLocalBasicForm from "./EditUserForm/PosLocalBasicForm";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}
interface ILocalPageProps {
  local?: any;
  tags: any[];
  groupsMembership: any[];
  groups: any[];
  roles: RoleGroup[];
  isFetchLocalRequest: boolean;
  isFetchTicketGroupsRequest: boolean;
  displayNotification: boolean;
  isEditRequest?: boolean;
  isGroupsMembershipRequest: boolean;
  getLocalAction: (id: string | number) => void;
  getGroupsMembershipAction: (id: string | number) => void;
  removeTicketGroupMemberAction: (
    id: string | number,
    groupId: string | number
  ) => Promise<any>;
  getTagsAction: () => void;
  getRolesGroupsAction: () => void;
  setLocalAction: (user: any) => void;
  setCurrentEditedUserAction: (name: string | null) => void;
  getTicketGroupsAction: (noLoader: boolean) => Promise<void>;
  uploadAvatarAction: (
    id: string | number,
    file: string | RcFile | Blob
  ) => Promise<any>;
  editLocalAction: (id: string | number, user: any) => Promise<any>;
  removeAvatarAction: (id: string | number) => Promise<any>;
  bulkAddTicketGroupMembershipAction: (
    id: string | number,
    groups: number[]
  ) => Promise<any>;
}

const LocalEditPage = (props: PropsWithChildren<any>): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();
  const {
    local,
    tags,

    bulkAddTicketGroupMembershipAction,
    removeTicketGroupMemberAction,
    setCurrentEditedUserAction,
    setLocalAction,
    uploadAvatarAction,
    editLocalAction,
    removeAvatarAction,
    isFetchLocalRequest,

    displayNotification,
  } = props;

  const onSave = (
    values: any,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    editLocalAction(id, {
      ...values,
    })
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        props.getLocalAction(id);
        openNotificationWithIcon("success", "Dane restauracji zapisane");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onUpload = (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => {
    uploadAvatarAction(id, file)
      .then((response: AxiosResponse) => {
        onRequestComplete(response.data.img);
        openNotificationWithIcon("success", "Avatar został zapisany");
      })
      .catch((error: AxiosError) => {
        onRequestComplete();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie zapisu avatara"
          );
        }
      });
  };

  const onAvatarRemove = (setRequestCompleted: () => void) => {
    removeAvatarAction(id)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Avatar został usunięty");
        setRequestCompleted();
      })
      .catch((error: AxiosError) => {
        setRequestCompleted();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie usuwania avataru"
          );
        }
      });
  };

  const onMembershipAdd = (
    values: IUserAddMembershipFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const newMemberships: number[] = values.memberships.map(
      (group: IUserAddMembership) => group.value
    );

    bulkAddTicketGroupMembershipAction(id, newMemberships)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon(
          "success",
          "Przydzielone działy zaktualizowane."
        );
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Wystąpił bład. Wybrany dział jest już przydzielony"
          );
        }
      });
  };
  const onMembershipRemove = (
    groupId: string | number,
    callbackRequestCompleted: () => void
  ) => {
    removeTicketGroupMemberAction(groupId, id)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Usunięto przydzielenie do działu");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
      });
  };

  useEffect(() => {
    if (id) props.getLocalAction(id);

    return () => {
      setCurrentEditedUserAction(null);
      setLocalAction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (local?.name) {
      setCurrentEditedUserAction(`${local.name}`);
    }
  }, [local, setCurrentEditedUserAction]);

  return (
    <Can renderError type="is_admin">
      <div className="user-edit-page">
        <MetaTitle
          title={
            local?.name
              ? `${local.name}  - Edycja restauracji`
              : "Edycja restauracji"
          }
          displayBadge={displayNotification}
        />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      {local?.type === "carrier_restaurant" ? (
                        <UserBasicForm
                          img={local?.img}
                          email={local?.email}
                          name={local?.name}
                          surname={local?.surname}
                          tag={local?.tag}
                          tags={tags}
                          phone={local?.phone}
                          state={local?.address?.state}
                          city={local?.address?.city}
                          street={local?.address?.street}
                          streetNumber={local?.address?.street_number}
                          deliveryPrice={local?.deliveryPrice}
                          typeAccount={local?.typeAccount}
                          isLoading={isFetchLocalRequest}
                          onUpload={onUpload}
                          onSave={onSave}
                          onAvatarRemove={onAvatarRemove}
                        />
                      ) : (
                        <PosLocalBasicForm
                          deliveryPrice={local?.deliveryPrice}
                          isLoading={isFetchLocalRequest}
                          onUpload={onUpload}
                          onAvatarRemove={onAvatarRemove}
                          onSave={onSave}
                        />
                      )}

                      {local?.type === "carrier_restaurant" && (
                        <>
                          <Divider />
                          <UserPasswordForm
                            isLoading={isFetchLocalRequest}
                            onSave={onSave}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getLocalAction: getLocal,
  uploadAvatarAction: uploadAvatar,
  removeAvatarAction: removeAvatar,
  editLocalAction: editLocal,
  getTagsAction: getTags,
  getRolesGroupsAction: getRolesGroups,
  getTicketGroupsAction: getTicketGroups,
  getGroupsMembershipAction: getTicketGroupMembership,
  removeTicketGroupMemberAction: removeTicketGroupMember,
  bulkAddTicketGroupMembershipAction: bulkAddTicketGroupMembership,
  setCurrentEditedUserAction: setCurrentEditedUser,
  setLocalAction: setLocal,
};

const mapStateToProps = (state: AppState) => {
  return {
    local: state.locals.local,
    tags: state.users.tags,
    groups: state.ticketgroups.ticketgroups,
    isFetchTicketGroupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    groupsMembership: state.users.groupsMembership,
    isFetchLocalRequest: state.locals.isFetchLocalRequest,
    isGroupsMembershipRequest: state.users.isGroupsMembershipRequest,
    isEditRequest: state.locals.isEditRequest,
    roles: state.roles.roles,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalEditPage);
