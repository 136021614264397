import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";
import { Product } from "../../types/Product";
import { ICategoryBasicFormValues } from "../../components/Pages/Categories/Forms/BasicForm";
import { IProductBasicFormValues } from "../../components/Pages/Products/Forms/BasicForm";

class ProductsService {
  getProducts = async (): Promise<AxiosResponse<Product[]>> => {
    const response = await api.get("products");
    return response;
  };

  getProduct = async (id: string | number): Promise<AxiosResponse<Product>> => {
    const response = await api.get(`products/${id}`);
    return response;
  };

  createProduct = async (
    category: Product | IProductBasicFormValues
  ): Promise<AxiosResponse> => {
    const response = await api.post(`products`, category);
    return response;
  };

  editProduct = async (
    id: number | string,
    category: Product | IProductBasicFormValues
  ): Promise<AxiosResponse> => {
    const response = await api.put(`products/${id}`, category);
    return response;
  };

  deleteProduct = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`products/${id}`);
    return response;
  };
}

export default new ProductsService();
