import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Col, Divider, Row, Tabs } from "antd";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import CategoriesService from "../../../services/categories-service";
import { Category } from "../../../types/Category";

import { openNotificationWithIcon } from "../../../utils/common";
import ProductBasicForm, { IProductBasicFormValues } from "./Forms/BasicForm";
import ProductsService from "../../../services/products-service";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}

const NewProductPage = (): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();

  const history = useHistory();

  const [categories, setCategories] = useState<Category[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const fetchCategories = () => {
    setIsRequest(true);
    CategoriesService.getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };
  const handleSave = (data: IProductBasicFormValues) => {
    setIsRequest(true);

    ProductsService.createProduct(data)
      .then((response) => {
        openNotificationWithIcon("success", "Produkt zapisany");
        history.push("/products");
      })
      .finally(() => setIsRequest(false));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="user-edit-page">
        <MetaTitle title="Nowa kategoria" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                      <ProductBasicForm
                        isLoading={isRequest}
                        categories={categories}
                        onSubmit={(data) => handleSave(data)}
                      />
                      <Divider />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default NewProductPage;
