import React, { useEffect, useState } from "react";

import { Button, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";

import { openNotificationWithIcon } from "../../../utils/common";

import { Product } from "../../../types/Product";
import ProductsService from "../../../services/products-service";
import ProductsList from "./list";

const ProductsPage = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleFetch = () => {
    setIsRequest(true);
    ProductsService.getProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const handleRemove = (id: string, callback: () => void) => {
    ProductsService.deleteProduct(id)
      .then(() => {
        handleFetch();
        openNotificationWithIcon("success", "Produkt usunięty");
      })
      .catch(() => {})
      .finally(() => {
        callback();
      });
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Can renderError type="is_admin">
      <div className="products-page">
        <MetaTitle title="Produkty" displayBadge={false} />
        <Row gutter={16}>
          <Col span={24}>
            <div className="products-page__header-actions">
              <Link to="/products/create">
                <Button type="primary">Nowy produkt</Button>
              </Link>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <ProductsList
                products={products}
                isRequest={isRequest}
                onDelete={(id, callback) => handleRemove(id, callback)}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

export default ProductsPage;
